import AWS from "aws-sdk";
import FileProvider from "context/file-upload-context";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Helper from "utils/helper";
export const S3_BUCKET = "ldbmixmaster";
export default function FileUploadWrapper(props) {
  const [sampleFile, setSampleFile] = useState({
    isUploading: false,
  });

  

  const REGION = "us-east-1";
  AWS.config.update({
    accessKeyId: "AKIA54BKUILSQZJD7ALH",
    secretAccessKey: "nF2dttOs4+jHunZ5HTA8CPxXmQh2uqTs4aWIRcUY",
  });


  AWS.config.httpOptions.timeout = 0;
  const userId = Helper.getUserId();

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const baseUrl = `https://stagingpdj-dev.s3.us-west-1.amazonaws.com/949/`;

  const toastId = React.useRef(null);

  const [File, setFile] = useState({
    isUploading: false,
  });

  const getFiles = (options = {}) => {
    var params = {
      Bucket: `${S3_BUCKET}`,
      ...options,
    };

    return new Promise((res, rej) => {
      
      myBucket.listObjects(params, (error, data) => {
        if (error) {
          res(error);
        }
        res(data?.Contents);
      });
    });

    // .send((error, data) => {
    //   console.log("asdasdad222", data);
    // });
  };

  const getBuckets = (options = {}) => {
    var params = {
      Bucket: `${S3_BUCKET}`,
      ...options,
    };

    return new Promise((res, rej) => {
      
      myBucket.listObjectsV2(params, (error, data) => {
        if (error) {
          res(error);
        }

        let folders = [];

        for(let i=0;i<data?.Contents?.length;i++){
          let folder = data?.Contents[i]?.Key?.split("/")?.[0];
          if(!folders?.includes(folder) && data?.Contents[i]?.Key?.includes("@")){
            folders.push(folder)
          }
        }
        console.log("folder",folders)
        res(folders);
      });
    });

    // .send((error, data) => {
    //   console.log("asdasdad222", data);
    // });
  };

  const deleteS3File = (fileName) => {
    return new Promise((res, rej) => {
      return myBucket
        .deleteObject({
          Bucket: `${S3_BUCKET}`,
          Key: fileName,
        })
        .send(() => {
          res();
        });
    });
  };



  
  const moveFile = (pid, fileName) => {
    var params = {
      Bucket: `${S3_BUCKET}/${pid}` /* Another bucket working fine */,
      CopySource: `${S3_BUCKET}/${userId}/${fileName}`,
      Key: pid /* required */,
    };
    myBucket.copyObject(params).send((err) => {
      myBucket
        .deleteObject({
          Bucket: `${S3_BUCKET}/${userId}`,
          Key: fileName,
        })
        .send();
    });
  };
  const uploadFile = async (
    requestPayload
  ) => {

   
    const file = requestPayload?.fileToUpload;
    const name = Helper.parseString(file?.file_name || file?.name || "");
    // let ext=  name?.split(".")?.pop();
    const key = `${requestPayload.folder}/${name}`;
    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: key,
    };

   
    const id = toast.loading(
      `0% ${name} ${
        "File"
      } Is Uploading...`
    );
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        const perc = Math.round((evt.loaded / evt.total) * 100);
        toast.update(id, {
          render: `${perc}% ${name} ${
            "File"
          } Is Uploading...`,
          type: "loadomg",
          isLoading: true,
        });
        setFile({
          ...File,
          isUploading: true,
          per: perc,
        });
      })
      .send((err) => {
      if(err){
        toast.update(id, {
          render: `Failed to Upload try again later `,
          type: "error",
          autoClose: 10000,
          closeButton: true,
          isLoading: false,
          onClick: ({ ...arges }) => { 
            toast.dismiss(id);
          },
        });

        return;
      }

        requestPayload.cb && requestPayload.cb();
        toast.update(id, {
          render: `100% ${name} ${
             "File"
          } Is Uploaded.`,
          type: "success",

          autoClose: 10000,
          closeButton: true,
          isLoading: false,
          onClick: ({ ...arges }) => { 
            toast.dismiss(id);
          },
        });
      
      });

   
  };

  return (
    <FileProvider
      value={{
        File: File,
        deleteS3File: deleteS3File,
        sampleFile: sampleFile,
        uploadFile: uploadFile,
        moveFile: moveFile,
        getFiles: getFiles,
        getBuckets:getBuckets
      }}
    >
      {props?.children}
    </FileProvider>
  );
}
